import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MensagemService {
  constructor(private http: HttpClient) {}

  getMensagens(usuarioAlvoId: number, moduloId?: any | number) {
    let params = new HttpParams();
    if (moduloId) {
      params = new HttpParams().set('modulo', moduloId);
    }
    return this.http.get<any>(`${environment.baseApiUrl}/mensagens/${usuarioAlvoId}`, { params });
  }
}
