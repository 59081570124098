import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { StudentMessage } from 'src/app/models/student-message';
import { environment } from 'src/environments/environment';
import { HubConnectionState } from '@aspnet/signalr';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  messageReceived = new EventEmitter<StudentMessage>();
  connectionEstablished = new EventEmitter<boolean>();
  connectionIsEstablished = false;
  hubConnection: HubConnection;

  constructor() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  sendMessage(message: StudentMessage) {
    this.hubConnection.invoke('SendMessage', message);
  }

  createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.baseUrl}/message`, { accessTokenFactory: () => localStorage.getItem('token') })
      .build();
  }

  startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        this.joinRoom();
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.connectionEstablished.emit(true);
      })
      .catch((err) => {
        console.error('Error while establishing connection, retrying...', err);
        setTimeout(() => this.startConnection(), 5000);
      });
  }

  joinRoom() {
    if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
      this.hubConnection.send('join');
    }
  }

  registerOnServerEvents() {
    this.hubConnection.on('messageReceived', (data: any) => {
      this.messageReceived.emit(data);
    });
  }
}
