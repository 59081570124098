import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { CorAlternativaQuestaoPipe } from './cor-alternativa-questao.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SafePipe, CorAlternativaQuestaoPipe],
  exports: [SafePipe, CorAlternativaQuestaoPipe],
})
export class PipesModule {}
