export class Aluno {
  id: number;
  nomeCompleto: string;
  imagem: string;
  especialidade: string;
  instituicao: string;
  cidade: string;
  estado: string;
  plano: string;
  usuarioId: number;
}
